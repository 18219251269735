import React, { useEffect } from "react";

// import './style.css';
import { Button, Form, FormProps, Input, Select, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";
import { useKycContext } from "../../../../../context/KycContext";
import { INDUSTRY, YES_NO } from "../../../../../utils/data";
import { getLocalStorageData } from "../../../../../utils/src/formatCuurency";
import { StepProps } from "../../../../../utils/types";
import { BusinessActivity } from "../../../../../utils/verify_types";

export const StepTwo: React.FC<StepProps> = () => {
  const {
    kycState,
    setIsSubmitting,
    setCounter,
    setBusinessActivity,
    setRefetch,
  } = useKycContext();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish: FormProps<BusinessActivity>["onFinish"] = (values) => {
    setIsSubmitting(true);
    if (values) {
      setBusinessActivity(values);
      localStorage.setItem("BusinessActivity", JSON.stringify(values));
      setRefetch();
      setIsSubmitting(false);
      setCounter("increment", "/developer-portal/kyc/ownership_structure");
      navigate("/developer-portal/kyc/ownership_structure");
    }
  };

  const onFinishFailed: FormProps<BusinessActivity>["onFinishFailed"] = (
    errorInfo
  ) => {
    return errorInfo;
  };

  useEffect(() => {
    const BusinessActivity: any = getLocalStorageData("BusinessActivity", {});
    if (BusinessActivity) {
      form.setFieldsValue({
        company_industry: BusinessActivity?.company_industry,
        company_industry_description: BusinessActivity?.company_industry,
        regulatory_institution_name:
          BusinessActivity?.regulatory_institution_name,
        has_legal_regulatory_conviction:
          BusinessActivity?.has_legal_regulatory_conviction,
        legal_regulatory_conviction_description:
          BusinessActivity?.legal_regulatory_conviction_description,
      });
    }
  }, []);

  return (
    <div className="">
      <Form
        form={form}
        name="BusinessActivity"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        className="flex flex-col pb-8 space-y-3"
      >
        <div className="grid grid-cols-2 gap-x-8 gap-y-0 pb-2 w-full">
          <Form.Item<BusinessActivity>
            label="Company Industry"
            name="company_industry"
            rules={[
              {
                required: true,
                message: "Please input your Company Industry!",
              },
            ]}
            className="w-full "
          >
            <Select
              placeholder="Select"
              showSearch={false}
              options={[...INDUSTRY]}
              size="large"
              className="text-left"
            />
          </Form.Item>

          <Form.Item<BusinessActivity>
            label="Company Industry Description"
            name="company_industry_description"
            rules={[
              {
                required: true,
                message: "Please input your description!",
              },
            ]}
            className="w-full "
          >
            <TextArea
              rows={8}
              placeholder={"Enter description"}
              className="text-area w-full"
            />
          </Form.Item>

          <Form.Item<BusinessActivity>
            name="has_legal_regulatory_conviction"
            label="Has Legal Regulatory Conviction"
            rules={[
              {
                required: true,
                message: "Please input your Financial Services!",
              },
            ]}
            className="w-full "
          >
            <Select
              placeholder="Select"
              showSearch={false}
              options={[...YES_NO]}
              size="large"
              className="text-left"
            />
          </Form.Item>

          <Form.Item<BusinessActivity>
            label="Name of Regulatory/Supervisory Institution"
            name="regulatory_institution_name"
            rules={[
              {
                required: true,
                message:
                  "Please input your Regulatory/Supervisory Institution!",
              },
            ]}
            className="w-full "
          >
            <Input
              placeholder={"Enter Regulatory/Supervisory Institution"}
              size="large"
            />
          </Form.Item>

          <Form.Item<BusinessActivity>
            label="Regulatory conviction description"
            name="legal_regulatory_conviction_description"
            rules={[
              {
                required: true,
                message: "Please input your description!",
              },
            ]}
            className="w-full "
          >
            <TextArea
              rows={8}
              placeholder={"Enter description"}
              className="text-area w-full"
            />
          </Form.Item>
        </div>

        <Space
          size={"middle"}
          align="baseline"
          style={{ float: "left" }}
          className="mt-10"
        >
          <Form.Item>
            <Button
              type="default"
              htmlType="button"
              className="bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white"
              style={{ height: "56px", fontWeight: "bold" }}
              onClick={() => {
                navigate("/developer-portal/kyc/corporate_information", {});
              }}
            >
              Back
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="small"
              className="bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation"
              style={{
                height: "56px",
                fontWeight: "bold",
              }}
              loading={kycState?.isSubmitting}
            >
              Save and Continue
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </div>
  );
};
