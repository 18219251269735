import { ConfigProvider } from "antd-country-phone-input";
import React from "react";
import { QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import en from "world_countries_lists/data/countries/en/world.json";
import { FormProvider } from "./context/FormContext";
import { KycProvider } from "./context/KycContext";
import { AppRouter } from "./routes/AppRouter";
import { queryClient } from "./services/react-query/queryClient";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={en}>
        <KycProvider>
          <FormProvider>
            <React.Fragment>
              <BrowserRouter>
                <AppRouter />
                {/* <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnHover={false}
                    role="alert"
                    containerId={"toaster-id"}
                    transition={Slide}
                  /> */}
                <ToastContainer />
              </BrowserRouter>
            </React.Fragment>
          </FormProvider>
        </KycProvider>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
