import { KeyOutlined } from "@ant-design/icons";
import { Button, Card, Input, Progress, Space, Spin, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Authentication from "../../assets/authentication.svg";
import Bank from "../../assets/bank.svg";
import ImtoDisabled from "../../assets/IMTOnigeriadisabled.svg";
import { ReactComponent as Star1 } from "../../assets/Star1.svg";
import { ReactComponent as Star2 } from "../../assets/Star2.svg";
import { ReactComponent as Verified } from "../../assets/verified.svg";
import {
  useGetOnboardingProfile,
  useGetUserRoleRights,
} from "../../hooks/profile/profile.hook";
import useResize from "../../hooks/utils/resize.hook";
import { useRequestAPIkey } from "../auth/developer/useAuth";
import "./style.css";

export const DeveloperPortal = (): JSX.Element => {
  const { size } = useResize();
  const navigate = useNavigate();
  const { rights } = useGetUserRoleRights();
  const { data, mutate } = useRequestAPIkey();
  const { onboarding, isFetching } = useGetOnboardingProfile();
  const [activeState, setActiveState] = useState<"test" | "uat" | "prod">(
    "test"
  );

  useEffect(() => {
    mutate();
  }, []);

  const authenticationRoute = () => {
    // return (window.location.href = "https://dev.monibag.com/portal/auth");
  };

  const [messageApi, contextHolder] = message.useMessage();

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(data?.data?.data)
      .then(() => {
        success();
      })
      .catch((err) => {
        console.error("Unable to copy text: ", err);
      });
  };

  const redirectVerify = () => {
    navigate("/developer-portal/kyc/corporate_information");
  };

  const ImtoCards = ({
    text,
    imageSrc,
    to,
  }: {
    text: string | React.ReactNode;
    imageSrc?: string | any;
    to?: string;
  }) => {
    return (
      <a
        className="flex flex-col space-y-2 cursor-pointer no-underline"
        href={`${to}`}
        target={text === "Pre-Fund Banks" ? "_self" : "_blank"}
        rel="noreferrer"
      >
        <Card
          style={{ width: 180 }}
          hoverable
          cover={<img alt="example" src={imageSrc} className="p-12" />}
          styles={{
            body: { padding: 0, cursor: "pointer" },
            actions: { cursor: "pointer" },
          }}
        ></Card>
        <p className="text-sm text-BagFoundation text-center mx-auto font-medium">
          {text}
        </p>
      </a>
    );
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: "API Key copied successfully",
    });
  };

  const rightsData: any = rights?.data?.data;
  console.log(rightsData && rightsData, "onboarding");

  return (
    <>
      {contextHolder}
      <div className="px-4 pt-8 flex flex-col space-y-5">
        <div className="border-b">
          <p className="all-user-text">Developers Portal</p>
        </div>

        {isFetching ? (
          <div className="mx-auto text-center">
            <Spin size="large" />
          </div>
        ) : (
          <>
            {rightsData && rightsData?.hasOwnProperty("AGENT") && (
              <div className="bg-BagDeveloperPortal rounded-lg py-8 flex flex-row items-start px-8">
                <div className="w-4/5 mx-auto flexd flex-rodw space-x-d4 relative">
                  {size[0] >= 768 && (
                    <Star2
                      className="float-left place-self-stretch text-xl absolute -bottom-[3%] -left-[6%]"
                      width={41}
                    />
                  )}
                  <div className="flex flex-col space-y-3 ">
                    <div className="flex flex-col space-y-2">
                      <span className="font-semibold text-BagFoundation text-2xl">
                        Developer Portal
                      </span>
                      {onboarding?.data?.data?.onboarding_status ===
                        "NOT_STARTED" && (
                        <span className="text-BagText font-semibold text-sm">
                          Please upload all required documents for compliance
                          verification{" "}
                        </span>
                      )}
                    </div>
                    {onboarding?.data?.data?.onboarding_status ===
                      "REVIEWED" && (
                      <div className="w-full flex flex-col">
                        <span className="float-end font-Poppins font-medium text-BagDarkShade text-right place-self-end text-sm">
                          In review
                        </span>
                        <Progress percent={90} showInfo={false} />
                      </div>
                    )}

                    {onboarding?.data?.data?.onboarding_status ===
                      "APPROVED" && (
                      <div className="w-full flex flex-col">
                        <span className="float-end place-self-end flex flex-row items-center space-x-2">
                          <Verified />
                          <span className=" font-Poppins font-medium text-BagDarkShade text-right text-sm">
                            verified
                          </span>
                        </span>

                        <Progress
                          percent={100}
                          showInfo={false}
                          strokeColor={"#F1B807"}
                          trailColor="#F1B807"
                        />
                      </div>
                    )}

                    {onboarding?.data?.data?.onboarding_status ===
                      "NOT_STARTED" && (
                      <div className="">
                        <button
                          className="verify-developer font-medium text-base"
                          onClick={redirectVerify}
                        >
                          Verify Account
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {size[0] >= 768 && <Star1 className="mr-8" />}
              </div>
            )}
          </>
        )}

        <div className="flex flex-col space-y-8 w-4/5 mx-auto">
          <div className="flex flex-row items-center space-x-4">
            <Button
              className=""
              style={{
                backgroundColor: activeState === "test" ? "#f1b900" : "#fff",
                width: "100px",
              }}
              onClick={() => setActiveState("test")}
            >
              Test
            </Button>
            <Button
              className="text-darkoption"
              type="primary"
              style={{
                backgroundColor: activeState === "uat" ? "#f1b900" : "#fff",
                padding: "10px !important",
                width: "100px",
                border: "1px solid #EAEAEA !important",
                color: "#000",
              }}
              onClick={() => setActiveState("uat")}
              disabled={
                onboarding?.data?.data?.onboarding_status !== "APPROVED"
              }
            >
              UAT
            </Button>
            <Button
              className=""
              type="primary"
              style={{
                backgroundColor: activeState === "prod" ? "#f1b900" : "#fff",
                border: "1px solid #EAEAEA !important",
                width: "109px",
                color: "#000",
              }}
              onClick={() => setActiveState("prod")}
              disabled={
                onboarding?.data?.data?.onboarding_status !== "APPROVED"
              }
            >
              Production
            </Button>
          </div>

          <div
            className="w-full rounded-md py-8"
            style={{ border: "1px solid #EAEAEA" }}
          >
            <div className="flex flex-col w-3/4 mx-auto">
              <p className="font-semibold text-base text-BagFoundation">
                Add request header x-api-key
              </p>
              <Space.Compact style={{ width: "85%" }}>
                {data?.data?.data && (
                  <Input.Password
                    defaultValue={data?.data?.data}
                    style={{ border: "1px solid #EAEAEA" }}
                    readOnly
                  />
                )}
                <Button
                  className="copy-key"
                  icon={<KeyOutlined />}
                  onClick={handleCopyClick}
                >
                  API Key
                </Button>
              </Space.Compact>
            </div>
          </div>

          <div className="flex flex-col space-y-5 ">
            <p className="text-BagDarkTitle border-b pb-3 font-medium text-base leading-6">
              APIs Section
            </p>

            <div className="flex flex-row items-center space-x-12 pb-5 pt-1">
              <ImtoCards
                text="Authentication API"
                imageSrc={Authentication}
                to="https://uat.api.monibag.com/portal/auth/#get-/actuator"
              />
              <ImtoCards
                text="IMTO Nigeria API"
                imageSrc={ImtoDisabled}
                to="https://uat.api.monibag.com/portal/imto-nga/"
              />
            </div>
          </div>

          <div className="flex flex-col space-y-5 pb-8">
            <p className="text-BagDarkTitle border-b pb-3 font-medium text-base leading-6">
              Information Section
            </p>
            <div className="flex flex-row items-center space-x-3 pb-5 pt-1">
              <ImtoCards
                text="Pre-Fund Banks"
                imageSrc={Bank}
                to="/developer-portal/prefund-banks"
              />
            </div>
          </div>
        </div>
        {/* <div className="developers-portal">
        <div className="overlap-group">
          <div className="DevFrame-wrapper">
            <div className="div">
              <div className="div-2">
                <div className="group">
                  <div className="div-wrapper">
                    <div className="text-wrapper">Developer Portal</div>
                  </div>
                </div>
                <p className="p">
                  Please upload all required documents for compliance
                  verification
                </p>
              </div>
              <div className="DevFrame-wrapper-2" onClick={redirectVerify}>
                <div className="div-wrapper-2 cursor-pointer">
                  <div className="text-wrapper-2 cursor-pointer">
                    Verify Account
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="star"
            src="https://c.animaapp.com/gJEU2wMl/img/star-2.svg"
            alt=""
          />
          <img
            className="img"
            src="https://c.animaapp.com/gJEU2wMl/img/star-1.svg"
            alt=""
          />
        </div>
      </div>
      <div className="div-3">
        <div className="div-4">
          <div className="div-wrapper-3">
            <div className="text-wrapper-3">Test</div>
          </div>
          <div className="div-wrapper-4">
            <div className="text-wrapper-4">UAT</div>
          </div>
          <div className="div-wrapper-5">
            <div className="text-wrapper-4">Production</div>
          </div>
        </div>
        <div className="DevFrame-wrapper-3">
          <div className="div-5">
            <div className="text-wrapper-5">Add request header x-api-key</div>
            <div className="div-6">
              <div
                className="vuesax-outline-eye-wrapper"
                onClick={handleCopyClick}
              >
                <span className="vuesax-outline-eye-text">
                  {" "}
                  {data?.data?.data}
                </span>
                <img
                  className="vuesax-outline-eye"
                  src="https://c.animaapp.com/gJEU2wMl/img/vuesax-outline-eye.svg"
                  alt=""
                />
              </div>
              <div className="div-7">
                <img
                  className="vuesax-outline-key"
                  src="https://c.animaapp.com/gJEU2wMl/img/vuesax-outline-key-1.svg"
                  alt=""
                />
                <div className="text-wrapper-6">API Key</div>
                {isCopied ? (
                  <span style={{ color: "#fff", fontWeight: "bolder" }}>
                    Copied!
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="div-8">
          <div className="div-9" onClick={authenticationRoute}>
            <div className="div-10">
              <div className="group-2">
                <img
                  className="vector"
                  src="https://c.animaapp.com/gJEU2wMl/img/vector-12.svg"
                  alt=""
                />
                <img
                  className="vector-2"
                  src="https://c.animaapp.com/gJEU2wMl/img/vector-43.svg"
                  alt=""
                />
                <img
                  className="vector-3"
                  src="https://c.animaapp.com/gJEU2wMl/img/vector-44.svg"
                  alt=""
                />
                <img
                  className="img-2"
                  src="https://c.animaapp.com/gJEU2wMl/img/monibagillustrationapi-1@2x.png"
                  alt=""
                />
              </div>
            </div>
            <div className="text-wrapper-7">Authentication API</div>
          </div>
          <div className="div-9">
            <div className="div-10">
              <div className="imtonigeria">
                <div className="overlap">
                  <div className="overlap-group-2">
                    <img
                      className="vector-4"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-11.svg"
                      alt=""
                    />
                    <img
                      className="vector-5"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-10.svg"
                      alt=""
                    />
                    <img
                      className="vector-6"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-9.svg"
                      alt=""
                    />
                    <img
                      className="vector-7"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-8.svg"
                      alt=""
                    />
                    <img
                      className="vector-8"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-7.svg"
                      alt=""
                    />
                    <img
                      className="vector-9"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-6.svg"
                      alt=""
                    />
                    <img
                      className="vector-10"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-3.svg"
                      alt=""
                    />
                    <div className="text-wrapper-8">$</div>
                    <img
                      className="vector-11"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-2.svg"
                      alt=""
                    />
                    <div className="text-wrapper-9">N</div>
                    <img
                      className="vector-12"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector-1.svg"
                      alt=""
                    />
                    <img
                      className="vector-13"
                      src="https://c.animaapp.com/gJEU2wMl/img/vector.svg"
                      alt=""
                    />
                  </div>
                  <img
                    className="vector-14"
                    src="https://c.animaapp.com/gJEU2wMl/img/vector-5.svg"
                    alt=""
                  />
                  <img
                    className="vector-15"
                    src="https://c.animaapp.com/gJEU2wMl/img/vector-4.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="text-wrapper-7">IMTO Nigeria API</div>
          </div>
        </div>
      </div> */}
      </div>
    </>
  );
};
