import { useLocation } from "react-router-dom";

export function checkIsHome(url: string, hash: string) {
  const home = "/home";
  const home2 = "/home/";
  const fqa = "/faq";
  const fqa2 = "/faq/";
  const terms = "/terms";
  const terms2 = "/terms/";
  const privacy = "/privacy";
  const privacy2 = "/privacy/";
  const sendMoney = "/home/#sendMoney";
  const howitworks = "/home/#howitworks";
  const features = "/home/#features";
  const mission = "/home/#mission";
  const contact = "/home/#contact";
  const index = "/";

  if (
    url === home ||
    url === home2 ||
    url === fqa ||
    url === fqa2 ||
    url === privacy ||
    url === privacy2 ||
    url === terms ||
    url === terms2 ||
    url === index ||
    (hash === sendMoney && url === home2) ||
    (hash === howitworks && url === home2) ||
    (hash === features && url === home2) ||
    (hash === mission && url === home2) ||
    (hash === contact && url === home2)
  ) {
    return true;
  }
  return false;
}

export function checkIsDeveloperPortal(url: string, hash: string) {
  const benefits = "#benefits";
  const about = "#about";
  const help = "#help";
  const mdahome = "/developer-portal";
  const mdahome2 = "/developer-portal/";

  if (
    url === mdahome ||
    (hash === benefits && url === mdahome2) ||
    (hash === about && url === mdahome2) ||
    (hash === help && url === mdahome2)
  ) {
    return true;
  }

  return false;
}

export function WhicHome() {
  const { pathname } = useLocation();
  const mdahome = "/developer-portal";
  const userHome = "/home";
  const helpCenter = "/help-center";
  const benefits = "#benefits";
  const about = "#about";
  const help = "#help";
  const mdahome2 = "/developer-portal/";

  if (
    mdahome === pathname ||
    helpCenter === pathname ||
    (window.location.hash === benefits && pathname === mdahome2) ||
    (window.location.hash === about && pathname === mdahome2) ||
    (window.location.hash === help && pathname === mdahome2)
  ) {
    return "mdaHome";
  }

  if (userHome === pathname) {
    return "userHome";
  }
  return "userHome";
}
