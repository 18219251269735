import { message } from "antd";
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { ErrorToast } from "../../admin-components/src/Toast";
import API_SERVICE from "../../services/api/service";
import {
  GET_ALL_COUNTRIES,
  GET_ALL_CURRENCY,
  GET_ALL_CURRENCY_PAIR,
  GET_ALL_Entities,
  GET_ALL_ONBOARDING_RULES,
  GET_ALL_Secret_Questions,
} from "../../services/react-query/constants";
import { Country, ICurrencyConversion } from "../../utils/types";

// reference/currency/getAll

export const useGetAllCurrency = () => {
  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: [GET_ALL_CURRENCY],
    queryFn: () => API_SERVICE._getAllCurrency(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      const errors = error?.response?.data?.errors;
      if (Array.isArray(errors)) {
        ErrorToast(errors[0]);
      } else if (typeof errors === "string") {
        ErrorToast(errors);
      }
    },
  });
  const currency = data;
  return { currency, isFetching, isLoading, refetch };
};

export const useGetAllCurrencyPair = () => {
  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: [GET_ALL_CURRENCY_PAIR],
    queryFn: () => API_SERVICE._getAllCurrencyPair(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      const errors = error?.response?.data?.errors;
      if (Array.isArray(errors)) {
        ErrorToast(errors[0]);
      } else if (typeof errors === "string") {
        ErrorToast(errors);
      }
    },
  });
  const currencyPair = data;
  return { currencyPair, isFetching, isLoading, refetch };
};

export const useGetAllEntities = () => {
  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: [GET_ALL_Entities],
    queryFn: () => API_SERVICE._getAllEntitie(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      const errors = error?.response?.data?.errors;
      if (Array.isArray(errors)) {
        ErrorToast(errors[0]);
      } else if (typeof errors === "string") {
        ErrorToast(errors);
      }
    },
  });
  const entity = data;
  return { entity, isFetching, isLoading, refetch };
};

export const useGetAllEntitiesResponse = () => {
  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: [GET_ALL_Entities],
    queryFn: () => API_SERVICE._getAllEntitieResponse(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      const errors = error?.response?.data?.errors;
      if (Array.isArray(errors)) {
        ErrorToast(errors[0]);
      } else if (typeof errors === "string") {
        ErrorToast(errors);
      }
    },
  });
  const entityResponse = data;
  return { entityResponse, isFetching, isLoading, refetch };
};

export const useGetAllOnboardingRules = () => {
  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: [GET_ALL_ONBOARDING_RULES],
    queryFn: () => API_SERVICE._getAllOnboardingRule(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      const errors = error?.response?.data?.errors;
      if (Array.isArray(errors)) {
        ErrorToast(errors[0]);
      } else if (typeof errors === "string") {
        ErrorToast(errors);
      }
    },
  });
  const onboardingRules = data;
  return { onboardingRules, isFetching, isLoading, refetch };
};

export const useGetAllSecretQuestions = () => {
  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: [GET_ALL_Secret_Questions],
    queryFn: () => API_SERVICE._getAllSecretQuestions(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      const errors = error?.response?.data?.errors;
      if (Array.isArray(errors)) {
        ErrorToast(errors[0]);
      } else if (typeof errors === "string") {
        ErrorToast(errors);
      }
    },
  });
  const Secret_Questions = data;
  return { Secret_Questions, isFetching, isLoading, refetch };
};

export const useGetAllCountries = () => {
  const { data, isFetching, isLoading, refetch, isError } = useQuery({
    queryKey: [GET_ALL_COUNTRIES],
    queryFn: () => API_SERVICE._getAllCountry(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      const errors = error?.response?.data?.errors;
      if (Array.isArray(errors)) {
        message.error(errors[0]);
      } else if (typeof errors === "string") {
        message.error(errors);
      }
    },
  });
  const countries = data;
  const country_json: Country[] = countries?.data?.data;
  const COUNTRY_JSON: any[] = country_json
    ? country_json?.map((i: Country) => {
        return {
          label: i.country_name,
          value: i.country_iso_3,
          stateCode: i.country_iso_2,
        };
      })
    : [];
  return { countries, COUNTRY_JSON, isFetching, isLoading, refetch, isError };
};

export const useGetAllCountriesMutation = () => {
  const getCountries = useMutation(
    (apiKey: string) => API_SERVICE._getAllCountry(apiKey),
    {
      onSuccess: (data: any) => {},
      onError: (error: any) => {},
    }
  );
  return { getCountries };
};

export const useSampleData = () => {
  const converter = useMutation(
    (data: ICurrencyConversion) => API_SERVICE._getSampleOrder(data),
    {
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );
  return { converter };
};

const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
