import { Button, Form, FormProps, Input, Select, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKycContext } from "../../../../../context/KycContext";
import { Periods, YES_NO } from "../../../../../utils/data";
import { getLocalStorageData } from "../../../../../utils/src/formatCuurency";
import { StepProps } from "../../../../../utils/types";
import { KycCdd } from "../../../../../utils/verify_types";

export const StepSeven: React.FC<StepProps> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { kycState, setIsSubmitting, setCounter, setKycCdd, setRefetch } =
    useKycContext();

  const is_aml_partially_or_fully_outsourced = Form.useWatch(
    "is_aml_partially_or_fully_outsourced",
    form
  );

  const onFinish: FormProps<KycCdd>["onFinish"] = (values) => {
    setIsSubmitting(true);
    if (values) {
      setKycCdd(values);
      localStorage.setItem("KycCdd", JSON.stringify(values));
      setCounter("increment");
      setRefetch();
      navigate(
        `/developer-portal/kyc/transaction_monitoring_and_suspicious_activity_reporting`
      );
      setIsSubmitting(false);
    }
  };

  const onFinishFailed: FormProps<KycCdd>["onFinishFailed"] = (errorInfo) => {
    return errorInfo;
  };

  useEffect(() => {
    const KycCdd: any = getLocalStorageData("KycCdd", {});

    if (KycCdd) {
      form.setFieldsValue({
        exist_approved_policy_procedure:
          KycCdd?.exist_approved_policy_procedure,
        onboarding_process_description: KycCdd?.onboarding_process_description,
        kyc_cdd_frequency: KycCdd?.kyc_cdd_frequency,
        is_kyc_cdd_frequency_risk_dependent:
          KycCdd?.is_kyc_cdd_frequency_risk_dependent,
        additional_high_risk_kyc_cdd_requirement:
          KycCdd?.additional_high_risk_kyc_cdd_requirement,
        approval_high_risk_kyc_cdd_requirement:
          KycCdd?.approval_high_risk_kyc_cdd_requirement,
        exist_pep_screening: KycCdd?.exist_pep_screening,
        exist_anonymous_on_behalf_of_transaction:
          KycCdd?.exist_anonymous_on_behalf_of_transaction,
        exist_corporate_ubo_policy_procedure:
          KycCdd?.exist_corporate_ubo_policy_procedure,
        is_aml_partially_or_fully_outsourced:
          KycCdd?.is_aml_partially_or_fully_outsourced,
        outsourced_aml_role_description:
          KycCdd?.outsourced_aml_role_description,
      });
    }
  }, []);

  return (
    <Form
      form={form}
      name="signInForm"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <div className="grid grid-cols-2 gap-x-8 gap-y-0 pb-2 w-full">
        <Form.Item<KycCdd>
          name="exist_approved_policy_procedure"
          label="Does the institution have written and Board approved policies and procedures for identification and verification and ongoing due diligence for Clients?"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>

        <Form.Item<KycCdd>
          name="onboarding_process_description"
          label="Describe the end-to-end process followed while on-boarding a new customer and the minimum documentation requirements?"
          rules={[
            {
              required: true,
              message: "Please describe your process",
            },
          ]}
        >
          <TextArea rows={8} placeholder={""} className="text-area w-full" />
        </Form.Item>

        <Form.Item<KycCdd>
          name="kyc_cdd_frequency"
          label="How frequently is the CDD/KYC information updated? *"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...Periods]}
            size="large"
            className="text-left"
          />
        </Form.Item>

        <Form.Item<KycCdd>
          name="is_kyc_cdd_frequency_risk_dependent"
          label="Does the frequency change depending on the risk rating of the customer?"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>

        <Form.Item<KycCdd>
          name="additional_high_risk_kyc_cdd_requirement"
          label="What additional due diligence requirements are placed on high-risk customers?"
          rules={[
            {
              required: true,
              message: "Please describe your requirements",
            },
          ]}
        >
          <TextArea rows={8} placeholder={""} className="text-area w-full" />
        </Form.Item>

        <Form.Item<KycCdd>
          name="approval_high_risk_kyc_cdd_requirement"
          label="what level of approval is required for such customers?"
          rules={[
            {
              required: true,
              message: "Please input your approval level",
            },
          ]}
        >
          <Input size="large" placeholder="" />
        </Form.Item>

        <Form.Item<KycCdd>
          name="exist_pep_screening"
          label="Does your institution have a system that supports effective PEP screenings?"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>

        <Form.Item<KycCdd>
          name="exist_anonymous_on_behalf_of_transaction"
          label="Does your institution maintain ‘anonymous’ accounts or perform transactions on behalf of customers without proof of identity?"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>
      </div>

      <Form.Item<KycCdd>
        name="exist_corporate_ubo_policy_procedure"
        label="Has the Institution developed policies/procedures on establishing/identifying the Ultimate Beneficial Ownership (UBO) of Corporate Entities?"
        className="w-2/3"
        rules={[
          {
            required: true,
            message: "Please select an option",
          },
        ]}
      >
        <Select
          placeholder=""
          options={[...YES_NO]}
          size="large"
          className="text-left"
        />
      </Form.Item>

      <div className="grid grid-cols-2 gap-x-8 gap-y-0 pb-2 w-full">
        <Form.Item<KycCdd>
          name="is_aml_partially_or_fully_outsourced"
          label="Do you engage any third-party vendors, contractors, or other to assist with or to carry out any function related to your AML Program?"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>

        <Form.Item<KycCdd>
          name="outsourced_aml_role_description"
          label="If yes, please describe their role and your vendor vetting process."
          rules={[
            {
              required: is_aml_partially_or_fully_outsourced === true,
              message: "Please describe the role of your vendor",
            },
          ]}
        >
          <TextArea rows={8} placeholder={""} className="text-area w-full" />
        </Form.Item>
      </div>

      <Space
        size={"middle"}
        align="baseline"
        style={{ float: "left" }}
        className="mt-10"
      >
        <Form.Item>
          <Button
            type="default"
            htmlType="button"
            className="bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white"
            style={{ height: "56px", fontWeight: "bold" }}
            onClick={() => navigate(`/developer-portal/kyc/risk_assessment`)}
          >
            Back
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="small"
            className="bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation"
            style={{
              height: "56px",
              fontWeight: "bold",
            }}
            loading={kycState.isSubmitting}
          >
            Save and Continue
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
