import { Button, Form, FormProps, Input, Select, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKycContext } from "../../../../../context/KycContext";
import { YES_NO } from "../../../../../utils/data";
import { getLocalStorageData } from "../../../../../utils/src/formatCuurency";
import { StepProps } from "../../../../../utils/types";
import { TransactionMonitoring } from "../../../../../utils/verify_types";

export const StepEight: React.FC<StepProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    kycState,
    setIsSubmitting,
    setCounter,
    setTransactionMonitoring,
    setRefetch,
  } = useKycContext();

  const onFinish: FormProps<TransactionMonitoring>["onFinish"] = (values) => {
    if (values) {
      setTransactionMonitoring(values);
      localStorage.setItem("TransactionMonitoring", JSON.stringify(values));
      setRefetch();
      setCounter("increment");
      navigate(`/developer-portal/kyc/sanction_screening`, {});
      setIsSubmitting(false);
    }
  };

  const onFinishFailed: FormProps<TransactionMonitoring>["onFinishFailed"] = (
    errorInfo
  ) => {
    return errorInfo;
  };

  useEffect(() => {
    const TransactionMonitoring: any = getLocalStorageData(
      "TransactionMonitoring",
      {}
    );

    if (TransactionMonitoring) {
      form.setFieldsValue({
        exist_automated_transaction_monitoring:
          TransactionMonitoring?.exist_automated_transaction_monitoring,
        transaction_monitoring_configuration_description:
          TransactionMonitoring?.transaction_monitoring_configuration_description,
        sar_ser_review_report_description:
          TransactionMonitoring?.sar_ser_review_report_description,
        monthly_average_transaction_monitoring_alert:
          TransactionMonitoring?.monthly_average_transaction_monitoring_alert,
        is_aml_cft_employee_training_up_to_date:
          TransactionMonitoring?.is_aml_cft_employee_training_up_to_date,
        exist_employee_aml_cft_violation_sanction:
          TransactionMonitoring?.exist_employee_aml_cft_violation_sanction,
        exist_employee_aml_cft_whistleblower_protection:
          TransactionMonitoring?.exist_employee_aml_cft_whistleblower_protection,
      });
    }
  }, []);

  return (
    <Form
      name="TransactionMonitoring"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <div className="grid grid-cols-2 gap-x-8 gap-y-7 pb-2 w-full">
        <Form.Item<TransactionMonitoring>
          name="exist_automated_transaction_monitoring"
          label="Is transaction monitoring automatic?"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>

        <Form.Item<TransactionMonitoring>
          name="transaction_monitoring_configuration_description"
          label="Describe briefly parameters, thresholds and transaction review triggers/alerts/red flags built into the monitoring system and how often they are reviewed?"
          rules={[
            {
              required: true,
              message: "Please describe your parameters",
            },
          ]}
        >
          <TextArea rows={8} placeholder={""} className="text-area w-full" />
        </Form.Item>

        <Form.Item<TransactionMonitoring>
          name="sar_ser_review_report_description"
          label="Provide an overview of the review and reporting process of Suspicious Activity Reports (SARs) / Suspicious Transaction Reports (STRs)"
          rules={[
            {
              required: true,
              message: "Please provide an overview of your report",
            },
          ]}
        >
          <TextArea rows={8} placeholder={""} className="text-area w-full" />
        </Form.Item>

        <Form.Item<TransactionMonitoring>
          name="monthly_average_transaction_monitoring_alert"
          label="What is the monthly average number of transaction monitoring alerts/red flags generated as a percentage of average total transactions in the last six months? "
          rules={[
            {
              required: true,
              message:
                "Please state your monthly average transaction monitoring",
            },
            {
              pattern: /^[0-9,]+$/,
              message:
                "Please enter only numbers, alphabets and special characters are not allowed",
            },
          ]}
        >
          <Input size="large" placeholder="Name" />
        </Form.Item>

        <Form.Item<TransactionMonitoring>
          name="is_aml_cft_employee_training_up_to_date"
          label="Are Staff trained to identify and report suspicious activities/transactions in line with AML/CFT."
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>

        <Form.Item<TransactionMonitoring>
          name="exist_employee_aml_cft_violation_sanction"
          label="Are there sanctions for employees who do not adhere to the AML/CFT policy?"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>

        <Form.Item<TransactionMonitoring>
          name="exist_employee_aml_cft_whistleblower_protection"
          label="Does your institution have appropriate mechanisms in place to protect staff against negative consequences resulting from reporting STR, or other actions complying with AML obligations"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>
      </div>

      <Space
        size={"middle"}
        align="baseline"
        style={{ float: "left" }}
        className="mt-10"
      >
        <Form.Item>
          <Button
            type="default"
            htmlType="button"
            className="bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white"
            style={{ height: "56px", fontWeight: "bold" }}
            onClick={() =>
              navigate(
                `/developer-portal/kyc/know_your_customer_and_customer_due_diligence`
              )
            }
          >
            Back
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="small"
            className="bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation"
            style={{
              height: "56px",
              fontWeight: "bold",
            }}
            loading={kycState.isSubmitting}
          >
            Save and Continue
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
