import { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
// import { logo, rightLayout2 } from "../../../../assets/images";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps, Space } from "antd";
// import moment from "moment";
import { dummyHead } from "../../../../../assets/images";
import { checkIsHome } from "../../../../../utils";
import SideBar from "./AgentSideBar";

interface AuthHeaderProps {
  showMenu: () => void;
  dashboardSidebar: boolean;
}

const AuthHeader: React.FC<AuthHeaderProps> = ({
  showMenu,
  dashboardSidebar,
}) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isHome = checkIsHome(pathname, window.location.hash);

  const toggleMenu = () => {
    showMenu();
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className="cursor-pointer"
          onClick={() => {
            sessionStorage.clear();
            navigate("/admin-portal/login", { replace: true });
          }}
        >
          <span className="text-gray-600">Logout</span>
        </div>
      ),
    },
  ];


  let userData = JSON.parse(sessionStorage.getItem('authData')!)
  let email = userData.email

  return (
    <div className="auth-layout-header">
      <nav className={`auth-nav`} id="auth-nav">
        <div className="auth-nav-center">
          {/*  Auth Nav header */}
          <div className="auth-nav-header">
            <div className="logo-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="38"
                viewBox="0 0 150 38"
                fill="none"
              >
                <path
                  d="M0 12.3862V9.2431H6.55672V11.7647C8.00556 9.69537 9.99076 8.66016 12.5123 8.66016C15.0339 8.66016 16.735 9.69537 17.6145 11.7647C19.0634 9.69537 21.0292 8.66016 23.5121 8.66016C25.3746 8.66016 26.8098 9.22265 27.8189 10.3488C28.828 11.4737 29.3325 13.1612 29.3325 15.4123V25.5383L31.5438 25.6554V28.7599H22.3099V25.811L23.5906 25.6951C23.978 25.644 24.2565 25.5338 24.4246 25.3656C24.5928 25.1974 24.6894 24.894 24.7155 24.4542V16.4214C24.7155 14.9987 24.5542 13.9453 24.2303 13.2589C23.9065 12.5737 23.1497 12.2306 21.961 12.2306C20.7713 12.2306 19.8588 12.593 19.2258 13.3169C18.5918 14.0419 18.2747 15.0112 18.2747 16.2271V25.5383L20.4088 25.6554V28.7599H11.2521V25.811L12.5328 25.6951C12.9203 25.644 13.2043 25.5338 13.3862 25.3656C13.5668 25.1974 13.6577 24.8804 13.6577 24.4145V16.4214C13.6577 14.9987 13.4953 13.9453 13.1725 13.2589C12.8487 12.5737 12.0862 12.2306 10.8828 12.2306C9.68054 12.2306 8.76805 12.6056 8.14761 13.3555C7.52716 14.1055 7.21694 15.0373 7.21694 16.1487V25.5383L9.31236 25.6554V28.7599H0.155679V25.811L1.43634 25.6951C1.82384 25.644 2.10792 25.5338 2.28974 25.3656C2.47042 25.1974 2.56133 24.8804 2.56133 24.4145H2.59996V13.7055C2.59996 13.2658 2.52837 12.9624 2.38633 12.7942C2.24428 12.626 1.99202 12.5294 1.62952 12.5033L0 12.3862Z"
                  fill="#29292A"
                />
                <path
                  d="M33.832 18.9816C33.832 17.068 34.1161 15.4066 34.6854 13.9953C35.2536 12.5862 36.0172 11.5067 36.9752 10.7556C38.8115 9.35901 40.8422 8.66016 43.0671 8.66016C44.6194 8.66016 45.9318 8.91242 47.0046 9.41696C48.0773 9.9215 48.9182 10.5033 49.5261 11.1635C50.1341 11.8226 50.6511 12.7805 51.0784 14.0339C51.5056 15.2885 51.7193 16.7691 51.7193 18.4771C51.7193 22.0463 50.8522 24.7497 49.1193 26.586C47.3864 28.4235 45.1682 29.3405 42.4649 29.3405C39.7615 29.3405 37.6467 28.4678 36.1206 26.7212C34.5945 24.9758 33.832 22.3963 33.832 18.9816ZM42.7558 25.9269C45.575 25.9269 46.9852 23.6508 46.9852 19.0986C46.9852 16.7964 46.6682 15.051 46.0341 13.8612C45.4 12.6715 44.3398 12.076 42.8524 12.076C41.3649 12.076 40.2717 12.6453 39.574 13.7839C38.8763 14.9226 38.5263 16.4748 38.5263 18.4396C38.5263 22.0861 39.199 24.3758 40.5433 25.3076C41.1387 25.7201 41.8762 25.9269 42.7558 25.9269Z"
                  fill="#29292A"
                />
                <path
                  d="M54.0859 12.3453V9.24197H60.6427V11.7635C61.3154 10.8067 62.2074 10.0499 63.3199 9.49423C64.4324 8.93856 65.6346 8.66016 66.9278 8.66016C68.8937 8.66016 70.413 9.22265 71.4868 10.3488C72.5595 11.4737 73.097 13.1612 73.097 15.4123V25.5383L75.3083 25.6553V28.7599H66.0744V25.811L67.3551 25.6951C67.7437 25.644 68.0278 25.5338 68.2085 25.3656C68.3891 25.1974 68.48 24.8804 68.48 24.4145V16.4203C68.48 14.9976 68.2596 13.9442 67.821 13.2578C67.3812 12.5726 66.5471 12.2294 65.3187 12.2294C64.0903 12.2294 63.1131 12.6169 62.3892 13.3942C61.6642 14.1703 61.3029 15.0885 61.3029 16.1487V25.5383L63.5142 25.6553V28.7599H54.2803V25.811L55.5609 25.6951C55.9484 25.644 56.2336 25.5338 56.4143 25.3656C56.595 25.1974 56.6859 24.8804 56.6859 24.4145V13.6669C56.6859 12.8908 56.362 12.4908 55.7155 12.4646L54.0859 12.3453Z"
                  fill="#29292A"
                />
                <path
                  d="M84.6203 9.24219V25.5374L86.8316 25.6544V28.7589H77.5977V25.8101L78.8783 25.6942C79.6283 25.6169 80.0033 25.2033 80.0033 24.4522V13.666C80.0033 13.2262 79.9317 12.9285 79.7897 12.774C79.6476 12.6183 79.3954 12.541 79.0329 12.541L77.6749 12.4637V9.24332H84.6203V9.24219Z"
                  fill="#29292A"
                />
                <path
                  d="M79.7327 5.88597C79.1896 5.35644 78.918 4.67689 78.918 3.84963C78.918 3.02237 79.1896 2.33034 79.7327 1.77353C80.2759 1.21786 80.9873 0.939453 81.8668 0.939453C82.7463 0.939453 83.4634 1.21786 84.0202 1.77353C84.5758 2.33034 84.8542 3.02237 84.8542 3.84963C84.8542 4.67803 84.5758 5.35644 84.0202 5.88597C83.4634 6.41665 82.7463 6.68142 81.8668 6.68142C80.9873 6.68142 80.2759 6.41665 79.7327 5.88597Z"
                  fill="#F0C92F"
                />
                <path
                  d="M90.7893 27.5953V5.32405C90.7893 4.72974 90.4655 4.41838 89.8189 4.39338L88.4609 4.31611V1.0957H95.2892V10.291C96.4529 9.20467 98.0176 8.6615 99.9846 8.6615C102.415 8.6615 104.343 9.48308 105.765 11.1251C107.188 12.7683 107.899 15.0762 107.899 18.0511C107.899 25.5272 104.77 29.2646 98.5097 29.2646C97.1642 29.2646 95.7483 29.1021 94.2609 28.7794C92.7734 28.4555 91.6166 28.0612 90.7893 27.5953ZM95.2892 15.0239V25.0726C96.2722 25.5908 97.4097 25.8487 98.704 25.8487C101.653 25.8487 103.128 23.4567 103.128 18.6704C103.128 14.3001 101.679 12.1126 98.7824 12.1126C97.8517 12.1126 97.037 12.3842 96.3381 12.9273C95.6392 13.4728 95.2892 14.1705 95.2892 15.0239Z"
                  fill="#29292A"
                />
                <path
                  d="M128.076 10.0189V24.4528C128.076 24.8664 128.146 25.1505 128.289 25.3062C128.431 25.4607 128.683 25.5516 129.046 25.5778L130.288 25.6562V28.7607H124.119V26.5107L124.002 26.4721C122.709 28.3345 120.937 29.2652 118.686 29.2652C116.047 29.2652 114.094 28.4254 112.827 26.7425C111.56 25.0619 110.926 22.7596 110.926 19.8369C110.926 16.3199 111.779 13.5779 113.486 11.6109C115.193 9.64505 117.754 8.66211 121.169 8.66211C123.368 8.66098 125.67 9.11324 128.076 10.0189ZM123.574 22.5903V12.5416C122.851 12.2052 121.854 12.0371 120.587 12.0371C118.854 12.0371 117.6 12.7359 116.823 14.1325C116.047 15.529 115.66 17.379 115.66 19.6813C115.66 23.8721 117.004 25.9664 119.695 25.9664C120.832 25.9664 121.764 25.6244 122.488 24.9391C123.212 24.2516 123.574 23.4687 123.574 22.5903Z"
                  fill="#29292A"
                />
                <path
                  d="M147.438 35.0453C146.015 36.3896 143.79 37.0623 140.764 37.0623C137.738 37.0623 135.371 36.6351 133.664 35.7817V30.3886L137.35 30.0783V31.9408C137.35 32.6397 137.673 33.1112 138.321 33.3567C138.967 33.6021 139.859 33.7249 140.998 33.7249C142.652 33.7249 143.817 33.226 144.49 32.2306C145.163 31.234 145.499 29.8306 145.499 28.0204V25.8863C144.257 27.3352 142.537 28.059 140.339 28.059C138.993 28.059 137.81 27.8011 136.789 27.2829C135.766 26.7659 134.972 26.067 134.403 25.1875C133.29 23.4284 132.734 21.3603 132.734 18.9796C132.734 15.5911 133.653 13.0241 135.489 11.2775C137.325 9.53206 139.769 8.6582 142.822 8.6582C145.356 8.6582 147.749 9.13661 150 10.0934V24.4875C150 26.6863 149.974 28.0954 149.923 28.717C149.688 31.5919 148.859 33.6999 147.438 35.0453ZM145.497 20.8444V12.5025C144.591 12.1922 143.557 12.0366 142.393 12.0366C140.815 12.0366 139.606 12.6184 138.766 13.7831C137.925 14.9467 137.505 16.5251 137.505 18.516C137.505 22.6034 138.849 24.6466 141.54 24.6466C142.651 24.6466 143.59 24.3046 144.354 23.6182C145.115 22.933 145.497 22.008 145.497 20.8444Z"
                  fill="#29292A"
                />
              </svg>
            </div>

            <div className="d-lg-none">
              <button
                className={`hamburger-button ${dashboardSidebar ? "open" : ""}`}
                onClick={toggleMenu}
              >
                <div className="bar"></div>
                <div className="bar"></div>
                <div className="bar"></div>
              </button>
            </div>

            <div className="d-none d-lg-flex headerLeftNav">
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="curor-pointer"
                >
                  <path
                    d="M8.99952 17.9999V18.7499C8.99952 19.5456 9.31559 20.3087 9.8782 20.8713C10.4408 21.4339 11.2039 21.7499 11.9995 21.7499C12.7952 21.7499 13.5582 21.4339 14.1208 20.8713C14.6834 20.3087 14.9995 19.5456 14.9995 18.7499V17.9999M5.26855 9.74994C5.26731 8.86045 5.44213 7.97951 5.78294 7.1579C6.12375 6.33628 6.62381 5.59025 7.25429 4.9628C7.88476 4.33535 8.6332 3.83889 9.45644 3.50204C10.2797 3.16519 11.1615 2.99461 12.0509 3.00013C15.7625 3.02772 18.7312 6.11275 18.7312 9.83468V10.4999C18.7312 13.8577 19.4337 15.8061 20.0524 16.871C20.1191 16.9848 20.1546 17.1142 20.1553 17.246C20.156 17.3779 20.1219 17.5076 20.0565 17.6221C19.991 17.7366 19.8966 17.8318 19.7826 17.8982C19.6686 17.9645 19.5392 17.9996 19.4073 17.9999H4.59173C4.45985 17.9996 4.33038 17.9645 4.2164 17.8981C4.10242 17.8318 4.00795 17.7366 3.94252 17.622C3.8771 17.5075 3.84303 17.3778 3.84376 17.2459C3.84449 17.114 3.87999 16.9846 3.94667 16.8709C4.56573 15.8059 5.26855 13.8575 5.26855 10.4999L5.26855 9.74994Z"
                    stroke="#3B4EE4"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              <div className="headerUsernameRole">
                {/* <div hidden>
                  <span className="fs-8 text-gray-800 fw-bold">
                    Hi, Demilade
                  </span>
                </div> */}
              </div>

              <div className={"headerUserAvatar"}>
                <div className="userImg">
                  <div className="dummyHead">
                    <span className="avatar-icon">
                      {email[0].toUpperCase()} {email[1].toUpperCase()}
                      </span>
                  </div>
                  {/* <img src={dummyHead} alt="" className="userImg" /> */}
                </div>

                <Dropdown menu={{ items }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.625 6.75L9 12.375L3.375 6.75"
                        stroke="#1F2937"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </a>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export { AuthHeader };
