import type { FormInstance } from "antd";
import { Button, Form } from "antd";
import React, { useEffect, useState } from "react";

export const SubmitButton = ({
  form,
  submitProp,
  isLoading,
  styles,
}: {
  form: FormInstance;
  submitProp?: any;
  isLoading?: any;
  styles?: React.CSSProperties;
}) => {
  const [submittable, setSubmittable] = React.useState(false);

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);

  return (
    <Button htmlType="submit" disabled={submittable === false ? true : false}>
      {isLoading ? (
        <span className="indicator-progress d-flex justify-content-center  align-items-center">
          <span className="d-none d-md-block">Please wait... </span>
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      ) : (
        submitProp || <span>Create Account</span>
      )}
    </Button>
  );
};

export const CustomButton = ({
  submitProp,
  classname,
  disabled,
  onClick,
  width,
  htmlType = "button",
}: {
  submitProp?: any;
  classname?: any;
  disabled?: boolean;
  onClick?: () => void | any;
  width?: any;
  htmlType?: "button" | "submit";
}) => {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Button
      htmlType={htmlType}
      disabled={disabled}
      className={`${
        width ? width : windowSize.width > 540 ? "w-350px" : "w-75"
      }`}
      onClick={onClick}
      style={{ background: disabled ? "#9ca3af" : "#F1B900" }}
    >
      {submitProp || <span>Proceed</span>}
    </Button>
  );
};
