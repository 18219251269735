import { message } from "antd";
import { useMutation, useQuery } from "react-query";
import API_SERVICE from "../../services/api/service";
import { instance } from "../../services/constants/instance";
import {
  GET_ONBOARDING_PROFILE_DETAILS,
  GET_ROLE_RIGHTS,
  GET_USER_PROFILE_DETAILS,
} from "../../services/react-query/constants";
import { IUpdatePerson, REQUEST_METHOD } from "../../utils/types";

export const useGetUserProfile = () => {
  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: [GET_USER_PROFILE_DETAILS],
    queryFn: () => API_SERVICE._getUserProfile(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      return error?.response;
    },
  });

  return { data, isFetching, isLoading, refetch };
};

export const useGetOnboardingProfile = () => {
  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: [GET_ONBOARDING_PROFILE_DETAILS],
    queryFn: () => API_SERVICE._getOnboardingProfile(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      return error?.response;
    },
  });

  return { onboarding: data, isFetching, isLoading, refetch };
};

export const useUpdateUserProfile = () => {
  const updateUserProfile = useMutation(
    (data: IUpdatePerson): any => {
      const { apiKey, token, ...rest } = data;
      return instance({
        url: `core/userProfile/update`,
        method: REQUEST_METHOD.PATCH,
        data: { ...rest },
        headers: { "x-api-key": apiKey, Authorization: `Bearer ${token}` },
      });
    },
    {
      onSuccess: (data: any) => {
        // message.success(data?.data?.message);
        return data;
      },
      onError: (error: any) => {
        const errors = error?.response?.data?.errors;
        if (Array.isArray(errors)) {
          message.error(errors[0]);
        } else if (typeof errors === "string") {
          message.error(errors);
        }
      },
    }
  );
  return { updateUserProfile };
};

export const useAddUserProfile = () => {
  const addUserProfile = useMutation(
    (data: IUpdatePerson): any => {
      const { apiKey, ...rest } = data;
      return instance({
        url: `core/userProfile/add`,
        method: REQUEST_METHOD.POST,
        data: { ...rest },
        // headers: { "x-api-key": apiKey },
      });
    },
    {
      onSuccess: (data: any) => {
        // message.success(data?.data?.message);
        return data;
      },
      onError: (error: any) => {
        const errors = error?.response?.data?.errors;
        if (Array.isArray(errors)) {
          message.error(errors[0]);
        } else if (typeof errors === "string") {
          message.error(errors);
        }
      },
    }
  );
  return { addUserProfile };
};

export const useGetUserRoleRights = () => {
  const { data, isFetching, isLoading, refetch } = useQuery({
    queryKey: [GET_ROLE_RIGHTS],
    queryFn: () => API_SERVICE._getUserRoleRights(),
    onSuccess: (data) => {
      return data;
    },
    onError: (error: any) => {
      return error?.response;
    },
  });

  return { rights: data, isFetching, isLoading, refetch };
};
