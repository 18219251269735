import {
  Button,
  DatePicker,
  Form,
  FormProps,
  Input,
  Select,
  Space,
} from "antd";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKycContext } from "../../../../../context/KycContext";
import { Periods, YES_NO } from "../../../../../utils/data";
import { getLocalStorageData } from "../../../../../utils/src/formatCuurency";
import { StepProps } from "../../../../../utils/types";
import { BusinessAudit } from "../../../../../utils/verify_types";

export const StepEleven: React.FC<StepProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    kycState,
    setIsSubmitting,
    setCounter,
    setBusinessAudit,
    setRefetch,
  } = useKycContext();

  const onFinish: FormProps<BusinessAudit>["onFinish"] = (values) => {
    if (values) {
      const payload = {
        ...values,
        last_aml_ctf_audit_assessment_date: dayjs(
          values?.last_aml_ctf_audit_assessment_date
        ).format("DD-MM-YYYY"),
      };
      setBusinessAudit(payload);
      localStorage.setItem("BusinessAudit", JSON.stringify(payload));
      setRefetch();
      setCounter("increment");
      navigate(`/developer-portal/kyc/aml_training`, {});
      setIsSubmitting(false);
    }
  };

  const onFinishFailed: FormProps<BusinessAudit>["onFinishFailed"] = (
    errorInfo
  ) => {
    return errorInfo;
  };

  useEffect(() => {
    const BusinessAudit: any = getLocalStorageData("BusinessAudit", {});

    if (BusinessAudit) {
      form.setFieldsValue({
        exist_non_governmental_aml_ctf_audit:
          BusinessAudit?.exist_non_governmental_aml_ctf_audit,
        non_governmental_aml_ctf_audit_frequency:
          BusinessAudit?.non_governmental_aml_ctf_audit_frequency,
        is_aml_ctf_audit_shared_with_company_directors:
          BusinessAudit?.is_aml_ctf_audit_shared_with_company_directors,
        last_aml_ctf_audit_assessment_regulator:
          BusinessAudit?.last_aml_ctf_audit_assessment_regulator,
        exist_last_aml_ctf_audit_assessment_material_finding:
          BusinessAudit?.exist_last_aml_ctf_audit_assessment_material_finding,
      });
    }
  }, []);

  return (
    <Form
      name="BusinessAudit"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <div className="grid grid-cols-2 gap-x-8 gap-y-7 pb-2 w-full">
        <Form.Item<BusinessAudit>
          name="exist_non_governmental_aml_ctf_audit"
          label="Is there an internal audit function or other independent third party, other than Government supervisors/regulators, that assesses AML/CTF policies and practices on a regular basis?"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>

        <Form.Item<BusinessAudit>
          name="non_governmental_aml_ctf_audit_frequency"
          label="What is the frequency of review?"
          rules={[
            {
              required: true,
              message: "Please input your",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...Periods]}
            size="large"
            className="text-left"
          />
        </Form.Item>
      </div>

      <Form.Item<BusinessAudit>
        name="is_aml_ctf_audit_shared_with_company_directors"
        label="Does the Board of Directors receive audit reports on 
AML/CFT?"
        className="w-2/3"
        rules={[
          {
            required: true,
            message: "Please select an option",
          },
        ]}
      >
        <Select
          placeholder=""
          options={[...YES_NO]}
          size="large"
          className="text-left"
        />
      </Form.Item>

      <div className="grid grid-cols-2 gap-x-8 gap-y-7 pb-2 w-full">
        <Form.Item<BusinessAudit>
          name="last_aml_ctf_audit_assessment_date"
          label="When was the last regulatory exam related to AML"
          rules={[
            {
              required: true,
              message: "Please select a data",
            },
          ]}
        >
          <DatePicker
            format={"DD-MM-YYYY"}
            picker="date"
            size="large"
            style={{ width: "100%", height: "50px" }}
          />
        </Form.Item>

        <Form.Item<BusinessAudit>
          name="last_aml_ctf_audit_assessment_regulator"
          label="which regulatory body conducted the 
exam?"
          rules={[
            {
              required: true,
              message: "Please input your",
            },
          ]}
        >
          <Input placeholder="" size="large" />
        </Form.Item>

        <Form.Item<BusinessAudit>
          name="exist_last_aml_ctf_audit_assessment_material_finding"
          label="Was there any major findings arising from the review?"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>
      </div>

      <Space
        size={"middle"}
        align="baseline"
        style={{ float: "left" }}
        className="mt-10"
      >
        <Form.Item>
          <Button
            type="default"
            htmlType="button"
            className="bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white"
            style={{ height: "56px", fontWeight: "bold" }}
            onClick={() => navigate(`/developer-portal/kyc/record_keeping`, {})}
          >
            Back
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="small"
            className="bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation"
            style={{
              height: "56px",
              fontWeight: "bold",
            }}
          >
            Save and Continue
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
