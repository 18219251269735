import { MinusCircleOutlined } from "@ant-design/icons";
import { Button, Form, FormProps, Input, Select, Space } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useKycContext } from "../../../../../context/KycContext";
import { YES_NO } from "../../../../../utils/data";
import { getLocalStorageData } from "../../../../../utils/src/formatCuurency";
import { StepProps } from "../../../../../utils/types";
import { SanctionScreening } from "../../../../../utils/verify_types";

export const StepNine: React.FC<StepProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const {
    kycState,
    setIsSubmitting,
    setCounter,
    setSanctionScreening,
    setRefetch,
  } = useKycContext();

  const exist_sanction_screening = Form.useWatch(
    "exist_sanction_screening",
    form
  );

  const onFinish: FormProps<SanctionScreening>["onFinish"] = (values) => {
    if (values) {
      const payload = {
        ...values,
        sanction_screening_list:
          values?.exist_sanction_screening === true
            ? values.sanction_screening_list
            : [""],
      };
      setSanctionScreening(payload);
      localStorage.setItem("SanctionScreening", JSON.stringify(payload));
      setRefetch();
      setCounter("increment");
      navigate(`/developer-portal/kyc/record_keeping`, {});
      setIsSubmitting(false);
    }
  };

  const onFinishFailed: FormProps<SanctionScreening>["onFinishFailed"] = (
    errorInfo
  ) => {
    return errorInfo;
  };

  useEffect(() => {
    const SanctionScreening: any = getLocalStorageData("SanctionScreening", {});
    if (SanctionScreening) {
      form.setFieldsValue({
        exist_sanction_screening: SanctionScreening?.exist_sanction_screening,
        sanction_screening_list:
          SanctionScreening?.exist_sanction_screening === true
            ? SanctionScreening?.sanction_screening_list
            : [],
      });
    }
  }, []);

  return (
    <Form
      name="SanctionScreening"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <div className="grid grid-cols-1  gap-y-7 pb-2 w-full">
        <Form.Item<SanctionScreening>
          name="exist_sanction_screening"
          label="Are procedures in place to screen and prohibit transactions to/from Sanctioned individuals/entities/countries"
          className="w-2/3"
          rules={[
            {
              required: true,
              message: "Please select an option",
            },
          ]}
        >
          <Select
            placeholder=""
            options={[...YES_NO]}
            size="large"
            className="text-left"
          />
        </Form.Item>

        {exist_sanction_screening === true && (
          <Form.Item
            label="Kindly provide lists against which customers and transactions are screened"
            rules={[
              {
                required: true,
                message:
                  "Please provide lists against which customers and transactions are screened",
              },
            ]}
          >
            <Form.List
              name="sanction_screening_list"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(new Error("At least one customer"));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message:
                              "Please input a value or delete this field.",
                          },
                        ]}
                        noStyle
                      >
                        <Input style={{ width: "50%" }} size="large" />
                      </Form.Item>
                      &nbsp;
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button text-xl"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <button
                      type="button"
                      onClick={() => add()}
                      className="border border-dashed rounded-md py-3 px-2 w-28"
                    >
                      Add field
                    </button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        )}
      </div>

      <Space
        size={"middle"}
        align="baseline"
        style={{ float: "left" }}
        className="mt-10"
      >
        <Form.Item>
          <Button
            type="default"
            htmlType="button"
            className="bg-BagFoundation px-6 font-medium text-sm font-Poppins outline-none shadow-none text-white hover:bg-BagFoundation hover:text-white"
            style={{ height: "56px", fontWeight: "bold" }}
            onClick={() =>
              navigate(
                `/developer-portal/kyc/transaction_monitoring_and_suspicious_activity_reporting`,
                {}
              )
            }
          >
            Back
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="small"
            className="bg-BagYellow px-6 font-medium text-sm font-Poppins text-BagFoundation hover:bg-BagYellow outline-none shadow-none hover:text-BagFoundation"
            style={{
              height: "56px",
              fontWeight: "bold",
            }}
          >
            Save and Continue
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
