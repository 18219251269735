import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import {
  check,
  home,
  humanLaptop,
  humanLock,
  humanPhone,
  humansSend,
  leftLayout,
  logo,
  rightLayout,
  rocketIcon,
  rocketLaunch,
} from "../../../assets/images";
import { Footer } from "./component/Footer";
import { Header } from "./component/Header";
type Props = {
  children?: any;
};

const DashboardLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  const features = [
    "Simple and fast remittance service",
    "Multi-currency bank account to support our global customers",
    "Mobile digital wallet for fast and reliable payment for good and services",
  ];

  const [route, setRoute] = useState<string>("");
  const [headerText, setHeaderText] = useState<string>("");
  const [bottomImg, setBottomImage] = useState<any>(null);

  useEffect(() => {
    setRoute(location.pathname);
    switch (location.pathname) {
      case "/signin":
        setHeaderText("Welcome Back!");
        setBottomImage(rocketLaunch);
        break;

      case "/signup":
        setHeaderText("SignUp");
        setBottomImage(rocketIcon);

        break;
      case "/forgot-password":
        setHeaderText("Forgot Password");
        setBottomImage(humansSend);

        break;
      case "/new-password":
        setHeaderText("New Password");
        setBottomImage(humanLaptop);

        break;

      case "/otpVerify":
        setHeaderText("OTP Verification");
        setBottomImage(humanPhone);

        break;

      case "/createPin":
        setHeaderText("Create Your Transaction PIN");
        setBottomImage(humanLock);

        break;
      default:
        setHeaderText("Welcome Back!");
        setBottomImage(rocketLaunch);
        break;
    }
  }, [location]);

  return (
    <div className="bg-danger">
      <div className="bg-dark layout-dark-container ">
        <Header />

        <div className="content-container">
          <Outlet />
        </div>

        <Footer />
      </div>
    </div>
  );
};

export { DashboardLayout };
