import React, { createContext, useContext, useState } from "react";
import {
  IAmountFormValues,
  IReceiverFormValues,
  ISenderFormValues,
} from "../utils/types";

interface Sender extends ISenderFormValues {}

interface Receiver extends IReceiverFormValues {}

interface Amount extends IAmountFormValues {}

interface FormState {
  payload: any;
  isSender: boolean;
  isReceiver: boolean;
  isAmount: boolean;
  sender: Sender;
  receiver: Receiver;
  amount: Amount;
}

interface FormContextType {
  formState: FormState;
  setSender: () => void;
  setReceiver: () => void;
  setAmount: () => void;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};

export const FormProvider = ({ children }: { children: React.ReactNode }) => {
  const [formState, setFormState] = useState<FormState>({
    payload: {},
    isSender: false,
    isReceiver: true,
    isAmount: true,
    sender: {
      sender_email: "",
      sender_business_name: "",
      sender_first_name: "",
      sender_last_name: "",
      sender_middle_name: "",
      sender_gender: "",
      sender_date_of_birth: "",
      sender_address_line_1: "",
      sender_address_line_2: "",
      sender_country_iso_3: "",
      sender_state: "",
      sender_city: "",
      sender_currency_iso_3: "",
      sender_phone_number: "",
      sender_identity_type: "",
      sender_identity_issuer_country_iso_3: "",
      sender_identity_number: "",
      sender_reason: "",
      sender_relationship: "",
      sender_industry: "",
      sender_payment_type: "",
      sender_entity_type: "",
      sender_secret_question: "",
      sender_secret_answer: "",
      agent_order_id: "",
      customer_id: "",
      event: "",
      sender_occupation: "",
      sender_post_zip_code: "",
    },
    receiver: {
      receiver_email: "",
      receiver_business_name: "",
      receiver_first_name: "",
      receiver_last_name: "",
      receiver_middle_name: "",
      receiver_gender: "",
      receiver_date_of_birth: "",
      receiver_country_iso_3: "",
      receiver_state: "",
      receiver_city: "",
      receiver_address_line_1: "",
      receiver_address_line_2: "",
      receiver_phone_number: "",
      receiver_identity_type: "",
      receiver_identity_issuer_country: "",
      receiver_identity_number: "",
      receiver_identity_expiry_date: "",
      receiver_bank_id: "",
      receiver_bank_country_iso_3: "",
      receiver_bank_account_number: "",
      receiver_currency_iso_3: "",
      receiver_relationship: "",
      receiver_occupation: "",
      receiver_industry: "",
      receiver_entity_type: "",
      receiver_post_zip_code: "",
    },
    amount: {
      receiver_amount: "",
      sender_amount: "",
      exchange_rate: "",
    },
  });

  const setSender = () => {
    setFormState((prevState) => ({ ...prevState, isSender: true }));
  };

  const setReceiver = () => {
    setFormState((prevState) => ({ ...prevState, isReceiver: false }));
  };

  const setAmount = () => {
    setFormState((prevState) => ({ ...prevState, isAmount: false }));
  };

  return (
    <FormContext.Provider
      value={{ formState, setSender, setReceiver, setAmount, setFormState }}
    >
      {children}
    </FormContext.Provider>
  );
};
