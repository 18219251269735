import {
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  MailOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { Menu, MenuProps } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
//import { getStoredUser } from "../../pages/auth/user-storage";

const { SubMenu } = Menu;
interface AgentSideBarProps {
  dashboardSidebar: Boolean;
  showMenu: () => void;
}

const hasRoleOAGFAdmin = (roles: any) => {
  return roles?.some((role: { id: string }) => role?.id === "ROLE_OAGF_ADMIN");
};

const AgentSideBar: React.FC<AgentSideBarProps> = ({
  dashboardSidebar,
  showMenu,
}) => {
  const [activeMenu, setActiveMenu] = useState("");

  //const authed = getStoredUser();

  const navigate = useNavigate();
  const location = useLocation();

  const generalStyle: any = {
    display: "grid",
    gap: "1rem",
    background: "#F2F2F2",

    height: 50,
  };

  const subMenuStyle: any = {
    background: "#FEF5D7",
    borderRadius: "4px",
    // fontFamily: "Poppins",
    // fontStyle: "normal",
    // fontWeight: 500,
    // fontSize: "14px",
    // lineHight: "21px",
    // color: "#061B01",
  };

  const currentPath = location.pathname;

  const getParentMenuKey = (path: string) => {
    if (path.startsWith("/dashbaord")) {
      return "dashboard";
    } else if (path.startsWith("/services")) {
      return "services";
    } else {
      return "dashboard"; // default parent menu key
    }
  };

  // Determine the parent menu key for the current route
  const currentParentMenuKey = getParentMenuKey(currentPath);

  // Set the active key and default selected keys
  const [activeKey, setActiveKey] = useState<string>(currentPath);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([
    currentParentMenuKey,
  ]);

  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleClick = (e: any) => {
    if (e.length !== 0) {
      setActiveKey(e);
    }
  };

  useEffect(() => {
    // Set the default selected keys based on the parent menu key
    setDefaultSelectedKeys([currentParentMenuKey]);
  }, [currentParentMenuKey]);

  const closeSidebar = () => {
    if (dashboardSidebar) {
      showMenu();
    }
  };

  // / style={generalStyle}
  // defaultOpenKeys={[activeKey]}

  return (
    <div
      className={`sidebarMain ${
        dashboardSidebar ? " sidebarMainActivated" : ""
      }`}
    >
      <nav className="overflow-hidden ">
        <Menu
          mode="inline"
          onOpenChange={handleClick}
          // style={generalStyle}
          // defaultOpenKeys={[activeKey]}
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={[currentParentMenuKey]}
          className="sidebarHeading"
          style={{ width: "256px", paddingRight: "20px" }}
        >
          <Menu.Item
            key="/admin-portal/dashboard"
            className={`menu-item
              ${
                currentPath === "/admin-portal/dashboard"
                  ? "text-primary active-menu"
                  : ""
              }
            `}
          >
            <Link
              className={`ps-0 ${
                currentPath === "/admin-portal/dashboard" ? "text-primary" : ""
              }`}
              onClick={closeSidebar}
              to="/admin-portal/dashboard"
            >
              <span style={{ marginRight: "8px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.77 13.75H15.73C13.72 13.75 12.75 12.82 12.75 10.9V4.1C12.75 2.18 13.73 1.25 15.73 1.25H19.77C21.78 1.25 22.75 2.18 22.75 4.1V10.9C22.75 12.82 21.77 13.75 19.77 13.75ZM15.73 2.75C14.46 2.75 14.25 3.09 14.25 4.1V10.9C14.25 11.91 14.46 12.25 15.73 12.25H19.77C21.04 12.25 21.25 11.91 21.25 10.9V4.1C21.25 3.09 21.04 2.75 19.77 2.75H15.73Z"
                    fill="#9D9D9D"
                  />
                  <path
                    d="M19.77 22.75H15.73C13.72 22.75 12.75 21.82 12.75 19.9V18.1C12.75 16.18 13.73 15.25 15.73 15.25H19.77C21.78 15.25 22.75 16.18 22.75 18.1V19.9C22.75 21.82 21.77 22.75 19.77 22.75ZM15.73 16.75C14.46 16.75 14.25 17.09 14.25 18.1V19.9C14.25 20.91 14.46 21.25 15.73 21.25H19.77C21.04 21.25 21.25 20.91 21.25 19.9V18.1C21.25 17.09 21.04 16.75 19.77 16.75H15.73Z"
                    fill="#9D9D9D"
                  />
                  <path
                    d="M8.27 22.75H4.23C2.22 22.75 1.25 21.82 1.25 19.9V13.1C1.25 11.18 2.23 10.25 4.23 10.25H8.27C10.28 10.25 11.25 11.18 11.25 13.1V19.9C11.25 21.82 10.27 22.75 8.27 22.75ZM4.23 11.75C2.96 11.75 2.75 12.09 2.75 13.1V19.9C2.75 20.91 2.96 21.25 4.23 21.25H8.27C9.54 21.25 9.75 20.91 9.75 19.9V13.1C9.75 12.09 9.54 11.75 8.27 11.75H4.23Z"
                    fill="#9D9D9D"
                  />
                  <path
                    d="M8.27 8.75H4.23C2.22 8.75 1.25 7.82 1.25 5.9V4.1C1.25 2.18 2.23 1.25 4.23 1.25H8.27C10.28 1.25 11.25 2.18 11.25 4.1V5.9C11.25 7.82 10.27 8.75 8.27 8.75ZM4.23 2.75C2.96 2.75 2.75 3.09 2.75 4.1V5.9C2.75 6.91 2.96 7.25 4.23 7.25H8.27C9.54 7.25 9.75 6.91 9.75 5.9V4.1C9.75 3.09 9.54 2.75 8.27 2.75H4.23Z"
                    fill="#9D9D9D"
                  />
                </svg>
              </span>
              {currentPath === "/dashboard" ? (
                <span className="text-active">Dashboard</span>
              ) : (
                "Dashboard"
              )}
            </Link>
          </Menu.Item>

          <Menu.Item
            key="/admin-portal/account-manager"
            className={`menu-item 
              ${
                currentPath === "/admin-portal/account-manager" ||
                currentPath === "/admin-portal/create-user"
                  ? "text-primary active-menu"
                  : ""
              }
            `}
          >
            <Link
              className={`ps-0 ${
                currentPath === "/admin-portal/account-manager" ||
                currentPath === "/admin-portal/create-user"
                  ? "text-primary"
                  : ""
              }`}
              onClick={closeSidebar}
              to="/admin-portal/account-manager"
            >
              <span style={{ marginRight: "8px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M9.15859 11.62C9.12859 11.62 9.10859 11.62 9.07859 11.62C9.02859 11.61 8.95859 11.61 8.89859 11.62C5.99859 11.53 3.80859 9.25 3.80859 6.44C3.80859 3.58 6.13859 1.25 8.99859 1.25C11.8586 1.25 14.1886 3.58 14.1886 6.44C14.1786 9.25 11.9786 11.53 9.18859 11.62C9.17859 11.62 9.16859 11.62 9.15859 11.62ZM8.99859 2.75C6.96859 2.75 5.30859 4.41 5.30859 6.44C5.30859 8.44 6.86859 10.05 8.85859 10.12C8.91859 10.11 9.04859 10.11 9.17859 10.12C11.1386 10.03 12.6786 8.42 12.6886 6.44C12.6886 4.41 11.0286 2.75 8.99859 2.75Z"
                    fill="#856504"
                  />
                  <path
                    d="M16.5384 11.75C16.5084 11.75 16.4784 11.75 16.4484 11.74C16.0384 11.78 15.6184 11.49 15.5784 11.08C15.5384 10.67 15.7884 10.3 16.1984 10.25C16.3184 10.24 16.4484 10.24 16.5584 10.24C18.0184 10.16 19.1584 8.96 19.1584 7.49C19.1584 5.97 17.9284 4.74 16.4084 4.74C15.9984 4.75 15.6584 4.41 15.6584 4C15.6584 3.59 15.9984 3.25 16.4084 3.25C18.7484 3.25 20.6584 5.16 20.6584 7.5C20.6584 9.8 18.8584 11.66 16.5684 11.75C16.5584 11.75 16.5484 11.75 16.5384 11.75Z"
                    fill="#856504"
                  />
                  <path
                    d="M9.17156 22.55C7.21156 22.55 5.24156 22.05 3.75156 21.05C2.36156 20.13 1.60156 18.87 1.60156 17.5C1.60156 16.13 2.36156 14.86 3.75156 13.93C6.75156 11.94 11.6116 11.94 14.5916 13.93C15.9716 14.85 16.7416 16.11 16.7416 17.48C16.7416 18.85 15.9816 20.12 14.5916 21.05C13.0916 22.05 11.1316 22.55 9.17156 22.55ZM4.58156 15.19C3.62156 15.83 3.10156 16.65 3.10156 17.51C3.10156 18.36 3.63156 19.18 4.58156 19.81C7.07156 21.48 11.2716 21.48 13.7616 19.81C14.7216 19.17 15.2416 18.35 15.2416 17.49C15.2416 16.64 14.7116 15.82 13.7616 15.19C11.2716 13.53 7.07156 13.53 4.58156 15.19Z"
                    fill="#856504"
                  />
                  <path
                    d="M18.3382 20.75C17.9882 20.75 17.6782 20.51 17.6082 20.15C17.5282 19.74 17.7882 19.35 18.1882 19.26C18.8182 19.13 19.3982 18.88 19.8482 18.53C20.4182 18.1 20.7282 17.56 20.7282 16.99C20.7282 16.42 20.4182 15.88 19.8582 15.46C19.4182 15.12 18.8682 14.88 18.2182 14.73C17.8182 14.64 17.5582 14.24 17.6482 13.83C17.7382 13.43 18.1382 13.17 18.5482 13.26C19.4082 13.45 20.1582 13.79 20.7682 14.26C21.6982 14.96 22.2282 15.95 22.2282 16.99C22.2282 18.03 21.6882 19.02 20.7582 19.73C20.1382 20.21 19.3582 20.56 18.4982 20.73C18.4382 20.75 18.3882 20.75 18.3382 20.75Z"
                    fill="#856504"
                  />
                </svg>
              </span>
              {currentPath === "/account-manager" ||
              currentPath === "/create-user" ? (
                <span className="text-active">Accounts</span>
              ) : (
                "Accounts"
              )}
            </Link>
          </Menu.Item>

          <SubMenu
            title={
              <Link
                className=""
                onClick={closeSidebar}
                to={"/admin-portal/finance/thresholds"}
              >
                <span style={{ marginRight: "8px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M22 6.31055H12C11.59 6.31055 11.25 5.97055 11.25 5.56055C11.25 5.15055 11.59 4.81055 12 4.81055H22C22.41 4.81055 22.75 5.15055 22.75 5.56055C22.75 5.98055 22.41 6.31055 22 6.31055Z"
                      fill="#9D9D9D"
                    />
                    <path
                      d="M19.7778 11.25H14.2278C12.0378 11.25 11.2578 10.48 11.2578 8.3V4.2C11.2578 2.02 12.0378 1.25 14.2278 1.25H19.7778C21.9678 1.25 22.7478 2.02 22.7478 4.2V8.31C22.7478 10.48 21.9678 11.25 19.7778 11.25ZM14.2178 2.75C12.8578 2.75 12.7478 2.86 12.7478 4.2V8.31C12.7478 9.65 12.8578 9.76 14.2178 9.76H19.7678C21.1278 9.76 21.2378 9.65 21.2378 8.31V4.2C21.2378 2.86 21.1278 2.75 19.7678 2.75H14.2178Z"
                      fill="#9D9D9D"
                    />
                    <path
                      d="M12 17.8105H2C1.59 17.8105 1.25 17.4705 1.25 17.0605C1.25 16.6505 1.59 16.3105 2 16.3105H12C12.41 16.3105 12.75 16.6505 12.75 17.0605C12.75 17.4805 12.41 17.8105 12 17.8105Z"
                      fill="#9D9D9D"
                    />
                    <path
                      d="M9.7778 22.75H4.22781C2.03781 22.75 1.25781 21.98 1.25781 19.8V15.7C1.25781 13.52 2.03781 12.75 4.22781 12.75H9.7778C11.9678 12.75 12.7478 13.52 12.7478 15.7V19.81C12.7478 21.98 11.9678 22.75 9.7778 22.75ZM4.2178 14.25C2.8578 14.25 2.7478 14.36 2.7478 15.7V19.81C2.7478 21.15 2.8578 21.26 4.2178 21.26H9.76779C11.1278 21.26 11.2378 21.15 11.2378 19.81V15.7C11.2378 14.36 11.1278 14.25 9.76779 14.25H4.2178Z"
                      fill="#9D9D9D"
                    />
                    <path
                      d="M15.0002 22.7502C14.7302 22.7502 14.4802 22.6002 14.3502 22.3702C14.2202 22.1302 14.2202 21.8502 14.3602 21.6102L15.4102 19.8602C15.6202 19.5102 16.0802 19.3902 16.4402 19.6002C16.8002 19.8102 16.9102 20.2702 16.7002 20.6302L16.4302 21.0802C19.1902 20.4302 21.2602 17.9502 21.2602 14.9902C21.2602 14.5802 21.6002 14.2402 22.0102 14.2402C22.4202 14.2402 22.7602 14.5802 22.7602 14.9902C22.7502 19.2702 19.2702 22.7502 15.0002 22.7502Z"
                      fill="#9D9D9D"
                    />
                    <path
                      d="M2 9.75C1.59 9.75 1.25 9.41 1.25 9C1.25 4.73 4.73 1.25 9 1.25C9.27 1.25 9.51999 1.4 9.64999 1.63C9.77999 1.87 9.78001 2.15 9.64001 2.39L8.59 4.14C8.38 4.49 7.92 4.61 7.56 4.4C7.2 4.19 7.08999 3.73 7.29999 3.37L7.57001 2.92C4.81001 3.57 2.73999 6.05 2.73999 9.01C2.74999 9.41 2.41 9.75 2 9.75Z"
                      fill="#9D9D9D"
                    />
                  </svg>
                </span>
                Finance
              </Link>
            }
            style={{ paddingLeft: "24px" }}
            className=""
            onTitleClick={toggleCollapsed}
          >
            <Menu.Item
              key="/admin-portal/finance/thresholds"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/finance/thresholds"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/finance/thresholds"
              >
                <span className="text-active">Thresholds</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/admin-portal/finance/prefund-banks"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/finance/prefund-banks"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/finance/prefund-banks"
              >
                <span className="text-active">Pre-Fund Banks</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/admin-portal/finance/prefund"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/finance/prefund"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/finance/prefund"
              >
                <span className="text-active">Pre-Funds</span>
              </Link>
              {/* /finance/feeband */}
            </Menu.Item>

            <Menu.Item
              key="/admin-portal/finance/funds"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/finance/funds"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/finance/funds"
              >
                <span className="text-active">Funds</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/admin-portal/finance/feeband"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/finance/feeband"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/finance/feeband"
              >
                <span className="text-active">Fee Band</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/admin-portal/finance/exchange-rate"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/finance/exchange-rate"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/finance/exchange-rate"
              >
                <span className="text-active">Exhange Rate</span>
              </Link>
            </Menu.Item>


                        <Menu.Item
              key="/admin-portal/finance/bankExchangeFund"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/finance/bankExchangeFund"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/finance/bankExchangeFund"
              >
                <span className="text-active">Bank Exchange Fund</span>
              </Link>
            </Menu.Item>
          </SubMenu>



{/* 
          <Menu.Item
            key="/transactions"
            className={`menu-item 
              ${
                currentPath === "/transactions"
                  ? "text-primary active-menu"
                  : ""
              }
             d-none`}
          >
            <Link onClick={closeSidebar} to="/transactions">
              <span style={{ marginRight: "8px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
                    fill="#9D9D9D"
                  />
                  <path
                    d="M15.5 19.25C13.98 19.25 12.75 18.02 12.75 16.5V7.5C12.75 5.98 13.98 4.75 15.5 4.75C17.02 4.75 18.25 5.98 18.25 7.5V16.5C18.25 18.02 17.02 19.25 15.5 19.25ZM15.5 6.25C14.81 6.25 14.25 6.81 14.25 7.5V16.5C14.25 17.19 14.81 17.75 15.5 17.75C16.19 17.75 16.75 17.19 16.75 16.5V7.5C16.75 6.81 16.19 6.25 15.5 6.25Z"
                    fill="#9D9D9D"
                  />
                  <path
                    d="M8.5 19.25C6.98 19.25 5.75 18.02 5.75 16.5V13C5.75 11.48 6.98 10.25 8.5 10.25C10.02 10.25 11.25 11.48 11.25 13V16.5C11.25 18.02 10.02 19.25 8.5 19.25ZM8.5 11.75C7.81 11.75 7.25 12.31 7.25 13V16.5C7.25 17.19 7.81 17.75 8.5 17.75C9.19 17.75 9.75 17.19 9.75 16.5V13C9.75 12.31 9.19 11.75 8.5 11.75Z"
                    fill="#9D9D9D"
                  />
                </svg>{" "}
              </span>
              {currentPath === "/transactions" ? (
                <span className="text-active">Threshold</span>
              ) : (
                "Threshold"
              )}
            </Link>
          </Menu.Item> */}





          <SubMenu
            title={
              <Link
                className=""
                onClick={closeSidebar}
                // to="/prefund"
                to={"/admin-portal/data-steward/banks"}
              >
                <span style={{ marginRight: "8px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M11.7884 11.88C11.2484 11.88 10.6984 11.78 10.2684 11.59L4.36844 8.97C2.86844 8.3 2.64844 7.4 2.64844 6.91C2.64844 6.42 2.86844 5.52 4.36844 4.85L10.2684 2.23C11.1384 1.84 12.4484 1.84 13.3184 2.23L19.2284 4.85C20.7184 5.51 20.9484 6.42 20.9484 6.91C20.9484 7.4 20.7284 8.3 19.2284 8.97L13.3184 11.59C12.8784 11.79 12.3384 11.88 11.7884 11.88ZM11.7884 3.44C11.4484 3.44 11.1184 3.49 10.8784 3.6L4.97844 6.22C4.36844 6.5 4.14844 6.78 4.14844 6.91C4.14844 7.04 4.36844 7.33 4.96844 7.6L10.8684 10.22C11.3484 10.43 12.2184 10.43 12.6984 10.22L18.6084 7.6C19.2184 7.33 19.4384 7.04 19.4384 6.91C19.4384 6.78 19.2184 6.49 18.6084 6.22L12.7084 3.6C12.4684 3.5 12.1284 3.44 11.7884 3.44Z" fill="#856504"/>
  <path d="M12 17.09C11.62 17.09 11.24 17.01 10.88 16.85L4.09 13.83C3.06 13.38 2.25 12.13 2.25 11C2.25 10.59 2.59 10.25 3 10.25C3.41 10.25 3.75 10.59 3.75 11C3.75 11.55 4.2 12.24 4.7 12.47L11.49 15.49C11.81 15.63 12.18 15.63 12.51 15.49L19.3 12.47C19.8 12.25 20.25 11.55 20.25 11C20.25 10.59 20.59 10.25 21 10.25C21.41 10.25 21.75 10.59 21.75 11C21.75 12.13 20.94 13.38 19.91 13.84L13.12 16.86C12.76 17.01 12.38 17.09 12 17.09Z" fill="#856504"/>
  <path d="M12 22.0883C11.62 22.0883 11.24 22.0083 10.88 21.8483L4.09 18.8283C2.97 18.3283 2.25 17.2183 2.25 15.9883C2.25 15.5783 2.59 15.2383 3 15.2383C3.41 15.2383 3.75 15.5883 3.75 15.9983C3.75 16.6283 4.12 17.2083 4.7 17.4683L11.49 20.4883C11.81 20.6283 12.18 20.6283 12.51 20.4883L19.3 17.4683C19.88 17.2083 20.25 16.6383 20.25 15.9983C20.25 15.5883 20.59 15.2483 21 15.2483C21.41 15.2483 21.75 15.5883 21.75 15.9983C21.75 17.2283 21.03 18.3383 19.91 18.8383L13.12 21.8583C12.76 22.0083 12.38 22.0883 12 22.0883Z" fill="#856504"/>
</svg>
                </span>
                Data Steward
              </Link>
            }
            style={{ paddingLeft: "24px" }}
            className=""
            onTitleClick={toggleCollapsed}
          >
            <Menu.Item
              key="/admin-portal/data-steward/banks"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/data-steward/banks"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/data-steward/banks"
              >
                <span className="text-active">Banks</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/admin-portal/data-steward/country"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/data-steward/country"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/data-steward/country"
              >
                <span className="text-active">Country</span>
              </Link>
            </Menu.Item>

                 <Menu.Item
              key="/admin-portal/data-steward/currency"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/data-steward/currency"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/data-steward/currency"
              >
                <span className="text-active">Currency</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/admin-portal/data-steward/currency/pair"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/data-steward/currency/pair"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/data-steward/currency/pair"
              >
                <span className="text-active">Currency Pair</span>
              </Link>
              {/* /finance/feeband */}
            </Menu.Item>

            <Menu.Item
              key="/admin-portal/data-steward/entities"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/data-steward/entities"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/data-steward/entities"
              >
                <span className="text-active">Entities</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/admin-portal/data-steward/entities-response"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/data-steward/entities-response"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/data-steward/entities-response"
              >
                <span className="text-active">Entities Response</span>
              </Link>
            </Menu.Item>

            <Menu.Item
              key="/admin-portal/data-steward/onboarding-rules"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/data-steward/onboarding-rules"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/data-steward/onboarding-rules"
              >
                <span className="text-active">Onboarding Rules</span>
              </Link>
            </Menu.Item>

                 <Menu.Item
              key="/admin-portal/data-steward/secret-questions"
              className={`menu-item 
              ${
                currentPath === "/admin-portal/data-steward/secret-questions"
                  ? "text-active loaded-state"
                  : ""
              } active-state
            `}
            >
              <Link
                className={`ps-0 `}
                onClick={closeSidebar}
                to="/admin-portal/data-steward/secret-questions"
              >
                <span className="text-active">Secret Question</span>
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </nav>
    </div>
  );
};

export default AgentSideBar;
