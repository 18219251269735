import React from "react";
import { Outlet, useLocation } from "react-router-dom";
type Props = {
  children?: any;
};

const AppsLayout: React.FC<Props> = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === "") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [location]);

  return (
    <div className="app-container">
      <Outlet />
    </div>
  );
};

export { AppsLayout };
