import { message } from "antd";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import ProgressBar from "../../../../components/src/ProgressBar/ProgressBar";
import { useLogout } from "../../../../hooks/auth/auth.hooks";
import { extractAndFormatPathSegment } from "../../../../utils/src/formatCuurency";
import "./style.css";

const VerifyAccount = (): JSX.Element => {
  const location = useLocation();
  const { logout } = useLogout();

  const signOut = () => {
    logout
      .mutateAsync()
      .then(() => {
        message.loading("logging out...");
      })
      .catch(() => {
        message.loading("logging out...");
      });
  };

  return (
    <div className="flex flex-col space-y-12 w-11/12 mx-auto text-center">
      <div className="navbar flex flex-row items-center justify-between py-8">
        <div className="">
          <img
            className="img cursor-pointer"
            src="https://c.animaapp.com/uXeCcQLH/img/frame-19.svg"
            alt="monibag"
          />
        </div>

        <button
          className="verify-account-login cursor-pointer"
          style={{ background: "red" }}
          onClick={signOut}
        >
          Sign Out
        </button>
      </div>

      <div className="flex flex-col space-y-6 w-3/4 mx-auto text-center">
        <div className="flex flex-col space-y-3">
          <p className="text-BagDarkShade font-semibold text-xl border-b pb-3">
            Tell Us More
          </p>
        </div>

        <div className="flex flex-col space-y-5">
          <div className="flex flex-col space-y-2">
            <h5
              className={`text-BagFoundation font-semibold text-lg text-left`}
            >
              {location?.pathname?.includes("aml")
                ? "AML Training"
                : extractAndFormatPathSegment(location?.pathname)}
            </h5>
          </div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default React.memo(VerifyAccount);
